/*MarkOT Fonts*/
@font-face {
  font-family: "MarkOT";
  src: url("../../fonts/MarkOT.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBlack";
  src: url("../../fonts/MarkOT-Black.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBlackItalic";
  src: url("../../fonts/MarkOT-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBold";
  src: url("../../fonts/MarkOT-Bold.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBoldItalic";
  src: url("../../fonts/MarkOT-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBook";
  src: url("../../fonts/MarkOT-Book.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTBookItalic";
  src: url("../../fonts/MarkOT-BookItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTExtraLight";
  src: url("../../fonts/MarkOT-ExtraLight.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTExtraLightItalic";
  src: url("../../fonts/MarkOT-ExtraLightItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTHairline";
  src: url("../../fonts/MarkOT-Hairline.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTHairlineItalic";
  src: url("../../fonts/MarkOT-HairlineItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTHeavy";
  src: url("../../fonts/MarkOT-Heavy.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTHeavyItalic";
  src: url("../../fonts/MarkOT-HeavyItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTItalic";
  src: url("../../fonts/MarkOT-Italic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTLight";
  src: url("../../fonts/MarkOT-Light.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTLightItalic";
  src: url("../../fonts/MarkOT-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTMedium";
  src: url("../../fonts/MarkOT-Medium.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTMediumItalic";
  src: url("../../fonts/MarkOT-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTThin";
  src: url("../../fonts/MarkOT-Thin.otf") format("opentype");
}
@font-face {
  font-family: "MarkOTThinItalic";
  src: url("../../fonts/MarkOT-ThinItalic.otf") format("opentype");
}
@font-face {
  font-family: "MarkOT-Ultra";
  src: url("../../fonts/MarkOT-Ultra.otf") format("opentype");
}